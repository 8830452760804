import { IsRunningOverwolf } from "utilityFunctions";
import googleLogo from "../assets/images/icons/google-logo.png";
import IOSAppStoreLogo from "../assets/images/icons/App-Store-iOS-logo.png";
import googlePlayStoreLogo from "../assets/images/icons/Google-Play-logo.png";
import overwolfLogo from "../assets/images/Overwolf-logo.png";
import reviewSvg from "../assets/svg/review.svg";
import { useTranslation } from "react-i18next";
import useApplicationInfo from "hooks/useAppInfo";

type Props = {
  desktopHeader?: boolean;
  backgroundColor?: string;
  textColor?: string;
  small?: boolean;
  noIcon?: boolean;
};

export const ReviewButton: React.FC<Props> = ({
  desktopHeader,
  backgroundColor,
  textColor,
  small,
  noIcon,
}) => {
  const isRunningOverwolf = IsRunningOverwolf();
  const { t } = useTranslation();
  const { application } = useApplicationInfo();

  const isRunningIos = application === "IOS_CLIENT";
  const isRunningAndroid = application === "ANDROID_CLIENT";

  const APPLE_APP_ID = "6468917016";
  const ANDROID_PACKAGE_NAME = "com.liifer.app";

  // Function to open the correct store app
  const openReviewPage = () => {
    if (isRunningOverwolf) {
      overwolf.utils.openStore({
        page: overwolf.utils.enums.eStorePage.ReviewsPage,
        uid: "jcedejgjifggfepoinicmboalnkldjcledokcdci",
      });
    } else if (isRunningIos) {
      // Open App Store app
      window.location.href = `itms-apps://apps.apple.com/app/id${APPLE_APP_ID}?action=write-review`;
    } else if (isRunningAndroid) {
      // Try to open Play Store app, fallback to web if it fails
      const playStoreUrl = `market://details?id=${ANDROID_PACKAGE_NAME}`;
      const webFallbackUrl = `https://play.google.com/store/apps/details?id=${ANDROID_PACKAGE_NAME}`;

      // Attempt to open Play Store app, fallback to web if it fails
      window.location.href = playStoreUrl;
      setTimeout(() => {
        window.location.href = webFallbackUrl;
      }, 1000);
    } else {
      // Open Google Review Page for non-mobile users
      window.open("https://g.page/r/CXqVZKKI_3SBEAI/review", "_blank");
    }
  };

  return (
    <>
      {desktopHeader ? (
        <button
          className="hover:opacity-70 transition duration-300 ease-in-out"
          onClick={openReviewPage}
        >
          <img className="h-5" src={reviewSvg} alt="Review" />
        </button>
      ) : (
        <button
          onClick={openReviewPage}
          className={`py-2 ${
            backgroundColor ? backgroundColor : "bg-slate-300"
          } ${
            textColor ? textColor : "text-slate-800"
          } rounded-lg font-bold hover:bg-opacity-70 transition ease-in-out duration-300 flex justify-center items-center space-x-2 ${
            small && noIcon
              ? "w-auto h-10 hover:opacity-70"
              : small
              ? "w-52 h-10"
              : "w-60 sm:w-56 h-12"
          }`}
        >
          <span className={`${small && noIcon ? "" : "pl-2"}`}>
            {t("review.leaveReview")}
          </span>
          {!noIcon && (
            <img
              className="h-7"
              src={
                isRunningIos
                  ? IOSAppStoreLogo
                  : isRunningAndroid
                  ? googlePlayStoreLogo
                  : isRunningOverwolf
                  ? overwolfLogo
                  : googleLogo
              }
              alt={
                isRunningIos || isRunningAndroid || isRunningOverwolf
                  ? "App Store"
                  : "Google"
              }
            />
          )}
        </button>
      )}
    </>
  );
};
