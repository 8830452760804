import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { Provider } from "react-redux";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import i18next from "i18next";
import "./index.css";
import App from "./App";

import { store } from "./store/store";
import logo from "./assets/images/Liifer_logo_max.png";
import { initReactI18next } from "react-i18next";
import { OverwolfApp } from "./overwolf/app/OverwolfApp";
import { ThirdPartyAuthProviders } from "components/auth/ThirdPartyAuthProviders";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000,
      retry: false,
    },
  },
});

export const BUILD_TARGET = {
  WEB: "WEB",
  OVERWOLF: "OVERWOLF",
  CAPACITOR: "CAPACITOR",
};

const el = document.getElementById("root");
if (el === null) throw new Error("Root container missing in index.html");

// const root = createRoot(el);

const buildTarget: string | undefined = process.env.REACT_APP_BUILD_TARGET;

console.log("this is build target", buildTarget);

if (
  buildTarget === BUILD_TARGET.WEB ||
  buildTarget === BUILD_TARGET.CAPACITOR
) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThirdPartyAuthProviders>
          <QueryClientProvider client={queryClient}>
            <Suspense
              fallback={
                <div className="flex items-center justify-center h-screen bg-[#08090d]">
                  <img
                    className="animate-pulse h-48 lg:h-60"
                    src={logo}
                    alt="logo"
                  />
                </div>
              }
            >
              <App IsOverwolfDisplay={false} />
            </Suspense>
            {/* <ReactQueryDevtools /> */}
          </QueryClientProvider>
        </ThirdPartyAuthProviders>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (buildTarget === BUILD_TARGET.OVERWOLF) {
  overwolf.settings.language.get(({ language }) => {
    i18next.use(initReactI18next).init(
      {
        lng: language,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
      },
      () => {
        ReactDOM.render(
          <React.StrictMode>
            <Provider store={store}>
              <ThirdPartyAuthProviders>
                <QueryClientProvider client={queryClient}>
                  <Suspense
                    fallback={
                      <div className="flex items-center justify-center h-screen bg-slate-900">
                        <img
                          className="animate-pulse h-48 lg:h-60"
                          src={logo}
                          alt="logo"
                        />
                      </div>
                    }
                  >
                    <OverwolfApp />
                  </Suspense>
                  <ReactQueryDevtools />
                </QueryClientProvider>
              </ThirdPartyAuthProviders>
            </Provider>
          </React.StrictMode>,
          document.getElementById("root")
        );
      }
    );
  });

  const changeLanguage = ({
    language,
  }: overwolf.settings.language.LanguageChangedEvent) =>
    i18next.changeLanguage(language);

  overwolf.settings.language.onLanguageChanged.removeListener(changeLanguage);
  overwolf.settings.language.onLanguageChanged.addListener(changeLanguage);
} else {
  throw "build target not included"; // eslint-disable-line
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
